import { Box } from "@mui/material";
import { ReactComponent as Favicon } from "../files/icons/Favicon.svg";

const Splashscreen = ({ noRoute = false }) => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="#030712"
    >
      <Box>
        <Favicon width={200} />
      </Box>
    </Box>
  );
};
export default Splashscreen;
